<template>
  <div>
    <KTCodePreview title="Cấu hình giá theo chi nhánh">
      <template v-slot:toolbar>
        <div class="row">
          <b-button
            variant="primary"
            size="sm"
            @click="openModal"
            class="font-weight-bolder"
            v-show="checkPermission('PRODUCT_PRICE_INSERT')"
          >
            <i class="flaticon2-add-1 add-button" style="font-size: 1rem"></i>Thêm mới
          </b-button>
        </div>
      </template>
      <template v-slot:preview>
        <div>
          <b-row class="mb-5">
            <b-col md="3">
              <vue-autosuggest
                v-model="searchProduct"
                :suggestions="filteredOptionsProduct"
                @selected="onSelectedProduct"
                :limit="10"
                @input="onInputChangProduct"
                :input-props="{
                  class: 'autosuggest__pp_product',
                  placeholder: 'Tìm kiếm',
                  style: 'border-radius:0px!important'
                }"
                :should-render-suggestions="
                  (size, loading) =>
                    size >= 0 && !loading && searchProduct !== ''
                "
              >
                <template slot-scope="{ suggestion }">
                  <div>
                    {{ suggestion.item.productName }}
                  </div>
                </template>
              </vue-autosuggest>
            </b-col>
            <b-col md="3">
              <multiselect
                v-model="valueStoreFilter"
                :options="optionStoreFilter"
                :multiple="true"
                track-by="id"
                :close-on-select="false"
                @select="onSelectStoreFilter($event)"
                @remove="onRemoveStoreFilter($event)"
                :show-labels="false"
                :showNoResults="false"
                :showPointer="false"
                :custom-label="customLabelStore"
                placeholder="Chọn cửa hàng"
              >
                <template slot="tag">{{ '' }}</template>
                <template slot="selection" slot-scope="{ values, isOpen }"
                  ><span
                    class="multiselect__single"
                    v-if="values.length &amp;&amp; !isOpen"
                    >{{ valueStoreFilter.length }} cửa hàng đã chọn</span
                  ></template
                >
                <template v-slot:no-result> Danh sách cửa hàng rỗng! </template>
                <span
                  class="checkbox-label"
                  slot="option"
                  slot-scope="scope"
                  @click.self="onSelectStoreFilter(scope.option)"
                >
                  {{ scope.option.name }}
                  <input
                    class="test"
                    type="checkbox"
                    v-model="scope.option.checked"
                    @focus.prevent
                  />
                </span>
                <span
                  class="checkbox-label"
                  style="font-size: 12px"
                  slot="noResult"
                >
                  Không có kết quả
                </span>
              </multiselect>
            </b-col>
            <b-col md="3">
              <b-form-select
                v-model="selectedStatus"
                :options="statusOptions"
                size="sm"
              >
              </b-form-select>
            </b-col>
            <b-col md="3">
              <b-button size="sm" variant="primary" @click="fetchData">
                Tìm kiếm
              </b-button>
            </b-col>
          </b-row>

          <b-table
            :items="productPrices"
            :fields="fields"
            bordered
            hover
            :busy="isLoading"
            :total-rows="totalItems"
            :current-page="currentPage"
            :per-page="0"
            :v-if="checkPermission('PRODUCT_PRICE_VIEW')"
          >
            <template v-slot:table-busy>
              <vcl-table :speed="5" :animate="true" :columns="20"></vcl-table>
            </template>
            <template #cell(index)="row">
              {{ row.index + 1 }}
            </template>

            <template #cell(sellingPrice)="row">
              <b style="color: #1bc5bd">
                {{ formatPrice(row.item.sellingPrice) }}
              </b>
            </template>

            <template #cell(status)="row">
              <span
                v-if="row.item.active === 1"
                class="label label-success label-inline font-weight-bold"
                >{{ statusName.active }}</span
              >
              <span
                v-else
                class="label label-danger label-inline font-weight-bold"
                >{{ statusName.inactive }}</span
              >
            </template>

            <template #cell(createdBy)="row">
              <span>
                {{ formatDate(row.item.createdAt) }}
              </span>
              <br />
              <span>---</span>
              <br />
              Bởi: <span v-text="row.item.createdBy"></span>
            </template>

            <template #cell(productName)="row">
              <span
                v-if="row.item.productBarCode"
                v-text="row.item.productBarCode"
                style="white-space: normal; font-size: 12px; font-weight: 500"
              ></span>
              <p
                v-if="row.item.productCode"
                style="font-size: 12px; font-weight: 500; margin-bottom: 0"
              >
                ( {{ row.item.productCode }} )
              </p>
              <span
                v-text="row.item.productName"
                style="white-space: normal"
              ></span>
            </template>

            <template #cell(actions)="row">
              <div class="d-flex justify-content-center">
                <b-dropdown size="sm" no-caret right>
                  <template slot="button-content">
                    <i
                      style="font-size: 1rem; padding-right: 0px"
                      class="flaticon2-settings"
                    ></i>
                  </template>
                  <b-dropdown-item
                    @click="editProductPrice(row.item)"
                    v-show="checkPermission('PRODUCT_PRICE_UPDATE')"
                  >
                    <span style="color: #3f4254; font-size: 12px">
                      <i style="font-size: 1rem" class="flaticon2-pen"></i>
                      &nbsp; Chỉnh sửa
                    </span>
                  </b-dropdown-item>

                  <b-dropdown-item
                    @click="showDeleteAlert(row.item)"
                    v-show="checkPermission('PRODUCT_PRICE_DELETE')"
                  >
                    <span style="color: #3f4254; font-size: 12px">
                      <i
                        style="font-size: 1rem; color: #d33"
                        class="flaticon2-rubbish-bin-delete-button"
                      ></i>
                      &nbsp; Xóa
                    </span>
                  </b-dropdown-item>
                </b-dropdown>
              </div>
            </template>
          </b-table>

          <b-row>
            <b-col>
              <p>Tổng số: {{ formatPrice(totalItems) }}</p>
            </b-col>
            <b-col>
              <b-pagination-nav
                v-model="currentPage"
                v-show="totalPage >= 2"
                :number-of-pages="totalPage"
                @change="handlePageChange"
                use-router
                :link-gen="linkGen"
                first-class="page-item-first btn btn-icon btn-sm mr-1 my-1"
                prev-class="page-item-prev btn btn-icon btn-sm mr-.5 my-1"
                next-class="page-item-next btn btn-icon btn-sm mr-1 my-1 ml-.5"
                last-class="page-item-last btn btn-icon btn-sm my-1 "
                page-class="btn btn-icon btn-sm border-0 mr-.5 my-1"
                align="right"
              >
                <template v-slot:first-text>
                  <span>
                    <i class="ki ki-bold-double-arrow-back icon-xs"></i>
                  </span>
                </template>

                <template v-slot:prev-text>
                  <i class="ki ki-bold-arrow-back icon-xs"></i>
                </template>

                <template v-slot:next-text>
                  <i class="ki ki-bold-arrow-next icon-xs"></i>
                </template>

                <template v-slot:last-text>
                  <span class="text-info">
                    <i class="ki ki-bold-double-arrow-next icon-xs"></i>
                  </span>
                </template>
              </b-pagination-nav>
            </b-col>
          </b-row>
        </div>
        <b-modal
          id="create-product-price-modal"
          ref="create-product-price-modal"
          button-size="sm"
          title="Thêm mới cấu hình"
          size="lg"
          no-close-on-backdrop
          hide-footer
        >
          <b-container fluid>
            <b-row class="mb-4">
              <b-col cols="3">Sản phẩm:</b-col>
              <b-col>
                <vue-autosuggest
                  v-model="searchProductModal"
                  :suggestions="filteredOptionsProductModal"
                  @selected="onSelectedProductModal"
                  :limit="10"
                  @input="onInputChangProductModal"
                  :input-props="{
                    class: 'autosuggest__pp_product',
                    placeholder: 'Tìm kiếm',
                    style: 'border-radius:0px!important'
                  }"
                  :should-render-suggestions="
                    (size, loading) =>
                      size >= 0 && !loading && searchProductModal !== ''
                  "
                >
                  <template slot-scope="{ suggestion }">
                    <div>
                      {{ suggestion.item.productName }}
                    </div>
                  </template>
                </vue-autosuggest>
              </b-col>
            </b-row>
            <b-row class="mb-4">
              <b-col cols="3">Chi nhánh:</b-col>
              <b-col>
                <multiselect
                  v-model="valueStoreModal"
                  :options="optionStoreModal"
                  :multiple="true"
                  track-by="id"
                  :close-on-select="false"
                  @select="onSelectStoreModal($event)"
                  @remove="onRemoveStoreModal($event)"
                  :show-labels="false"
                  :showNoResults="false"
                  :showPointer="false"
                  :custom-label="customLabelStore"
                  placeholder="Chọn ít nhất một cửa hàng"
                >
                  <template slot="tag">{{ '' }}</template>
                  <template slot="selection" slot-scope="{ values, isOpen }"
                    ><span
                      class="multiselect__single"
                      v-if="values.length &amp;&amp; !isOpen"
                      >{{ valueStoreModal.length }} cửa hàng đã chọn</span
                    ></template
                  >
                  <template v-slot:no-result>
                    Danh sách cửa hàng rỗng!
                  </template>
                  <span
                    class="checkbox-label"
                    slot="option"
                    slot-scope="scope"
                    @click.self="onSelectStoreModal(scope.option)"
                  >
                    {{ scope.option.name }}
                    <input
                      class="test"
                      type="checkbox"
                      v-model="scope.option.checked"
                      @focus.prevent
                    />
                  </span>
                  <span
                    class="checkbox-label"
                    style="font-size: 12px"
                    slot="noResult"
                  >
                    Không có kết quả
                  </span>
                </multiselect>
              </b-col>
            </b-row>
            <b-row class="mb-4">
              <b-col cols="3">Giá bán:</b-col>
              <b-col>
                <b-form-group class="required-control" style="padding: 0px">
                  <b-form-input
                    size="sm"
                    v-model="sellingPrice"
                    placeholder="Nhập giá bán của sản phẩm"
                    v-mask="mask"
                  ></b-form-input>
                </b-form-group>
              </b-col>
            </b-row>

            <b-row class="mt-5">
              <b-col class="d-flex">
                <b-overlay
                  opacity="0.6"
                  spinner-variant="white"
                  spinner-type="grow"
                  spinner-small
                >
                  <b-button
                    style="fontweight: 600; width: 70px"
                    variant="primary"
                    size="sm"
                    @click="createProductPrice"
                    >Lưu</b-button
                  >
                </b-overlay>

                <b-button
                  style="margin-left: 10px; font-weight: 600; width: 70px"
                  variant="secondary"
                  size="sm"
                  @click="$bvModal.hide('create-product-price-modal')"
                  >Hủy</b-button
                >
              </b-col>
            </b-row>
          </b-container>
        </b-modal>
      </template>
    </KTCodePreview>
  </div>
</template>

<script>
import KTCodePreview from '@/view/content/CodePreview.vue';
import { VclTable } from 'vue-content-loading';
import { SET_MENU } from '@/core/services/store/modules/menu.module';
import { SideBar } from '@/core/config/menu/sideBar';
import ApiService from '../../../core/services/api.service';
import Multiselect from 'vue-multiselect';
import decounce from 'debounce';
import { VueAutosuggest } from 'vue-autosuggest';
import { SET_BREADCRUMB } from '@/core/services/store/modules/breadcrumbs.module';
import {
  formatDate,
  makeToastSuccess,
  makeToastFaile,
  currencyMask,
  unMaskPrice,
  formatPrice
} from '../../../utils/common';
import { get } from 'lodash';
import { checkPermission } from '../../../utils/saveDataToLocal';
import Swal from 'sweetalert2';
import { v4 } from 'uuid';

export default {
  components: {
    KTCodePreview,
    VclTable,
    Multiselect,
    VueAutosuggest
  },
  data() {
    return {
      productPrices: [],
      fields: [
        {
          key: 'index',
          label: 'STT',
          thStyle: { textAlign: 'center', width: '3%' },
          tdClass: 'text-center align-middle'
        },
        {
          key: 'productName',
          label: 'Tên sản phẩm',
          thStyle: { textAlign: 'center', width: '30%' },
          tdClass: 'text-left align-middle'
        },
        {
          key: 'sellingPrice',
          label: 'Giá bán',
          thStyle: { textAlign: 'center', width: '8%' },
          tdClass: 'text-right align-middle'
        },
        {
          key: 'storeName',
          label: 'Kho hàng',
          thStyle: { textAlign: 'center', width: '6%' },
          tdClass: 'text-left align-middle'
        },
        {
          key: 'status',
          label: 'Trạng thái',
          thStyle: { textAlign: 'center', width: '6%' },
          tdClass: 'text-center align-middle'
        },
        {
          key: 'createdBy',
          label: 'Thời gian tạo',
          thStyle: { textAlign: 'center', width: '12%' },
          tdClass: 'text-center'
        },
        {
          key: 'actions',
          label: 'Thao tác',
          thStyle: { width: '5%', textAlign: 'center' },
          tdClass: 'text-center align-middle'
        }
      ],
      statusOptions: [
        {
          value: null,
          text: 'Chọn trạng thái'
        },
        {
          value: 1,
          text: 'Hoạt động'
        },
        {
          value: 0,
          text: 'Khóa'
        }
      ],
      selectedStatus: null,
      statusName: {
        active: 'Hoạt động',
        inactive: 'Khóa'
      },
      isLoading: false,
      linkGen: pageNum => {
        return pageNum === 1 ? '?' : `?page=${pageNum}`;
      },
      optionStoreFilter: [],
      valueStoreFilter: [],
      optionStoreModal: [],
      valueStoreModal: [],
      sellingPrice: null,
      mask: currencyMask,
      searchProduct: '',
      filteredOptionsProduct: [],
      isSearching: false,
      listProduct: [],
      optionsProduct: [
        {
          data: []
        }
      ],
      productIdFilter: null,
      searchProductModal: '',
      productIdModal: null,
      filteredOptionsProductModal: [],
      isModalSearching: false,
      isBusy: false,
      currentPage: 1,
      perPage: 20,
      totalItems: 0,
      totalPage: null
    };
  },
  mounted() {
    this.$store.dispatch(SET_MENU, SideBar.PRODUCT);
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: 'Sản phẩm', route: '/products' },
      { title: 'Cấu hình giá theo chi nhánh', route: '/product-price' }
    ]);
  },
  created() {
    const initPage = this.$route.query.page;
    this.currentPage = initPage ? initPage : 1;
    this.fetchStore();
    this.fetchData();
  },
  methods: {
    formatPrice,
    formatDate,
    resetFilter() {
      this.selectedStatus = null;
      this.optionStoreFilter = [];
      this.productIdFilter = null;
    },
    addNew() {},
    fetchMore() {},
    fetchData() {
      this.isLoading = true;
      this.productPrices = [];

      let queryList = [];
      if (this.selectedStatus !== null) {
        queryList.push(`active=${this.selectedStatus}`);
      }

      const storeIds = this.optionStoreFilter.filter(store => store.checked);
      if (storeIds.length) {
        storeIds.forEach(storeId => {
          queryList.push(`storeIds=${storeId.code}`);
        });
      }

      if (this.productIdFilter) {
        queryList.push(`productId=${this.productIdFilter}`);
      }

      queryList.push(`pageSize=${this.perPage}`);
      queryList.push(`pageNumber=${this.currentPage}`);

      const query = `?${queryList.join('&')}`;

      ApiService.get(`/product_price${query}`).then(response => {
        const data = response.data.data;
        this.productPrices = data.data;

        // pagination
        this.totalItems = data.totalRow;
        this.currentPage = data.currentPage;
        this.totalPage = data.totalPage;

        this.isLoading = false;
      });
    },
    customLabelStore(option) {
      return `${option.name}`;
    },
    fetchStore: async function () {
      this.optionStoreFilter = [];
      this.optionStoreModal = [];

      ApiService.get(`/stores/getStores`).then((response) => {
        const stores = response.data.data;
        this.optionStoreFilter = stores.map((element) => {
          return {
            id: v4(),
            code: element.id,
            name: element.shortName,
            checked: false,
          };
        });
        this.optionStoreModal = stores.map((element) => {
          return {
            id: v4(),
            code: element.id,
            name: element.shortName,
            checked: false,
          };
        });
      });
    },
    onSelectStoreFilter(option) {
      let index = this.optionStoreFilter.findIndex(
        (item) => item.name == option.name
      );
      this.optionStoreFilter[index].checked = true;
    },
    onRemoveStoreFilter(option) {
      let index = this.optionStoreFilter.findIndex(
        (item) => item.name == option.name
      );
      this.optionStoreFilter[index].checked = false;
    },
    onSelectStoreModal(option) {
      let index = this.optionStoreModal.findIndex(
        item => item.name == option.name
      );
      this.optionStoreModal[index].checked = true;
    },
    onRemoveStoreModal(option) {
      let index = this.optionStoreModal.findIndex(
        item => item.name == option.name
      );
      this.optionStoreModal[index].checked = false;
    },
    openModal() {
      this.optionStoreModal.forEach((element) => {
        element.checked = false;
      });
      this.valueStoreModal = [];
      this.$refs['create-product-price-modal'].show();
    },
    closeModal() {
      this.$refs['create-product-price-modal'].hide();
    },
    onInputChangProduct(text) {
      if (!text || text === '') {
        this.searchProduct = null;
        this.productIdFilter = null;
      } else {
        this.searchProduct = text;
      }

      this.debounceInputProductFilter();
    },
    onInputChangProductModal(text) {
      if (!text || text === '') {
        this.searchProductModal = null;
        this.productIdModal = null;
      } else {
        this.searchProductModal = text;
      }

      this.debounceInputProductModal();
    },
    debounceInputProductFilter: decounce(function() {
      this.searchProductInFilter();
    }, 500),
    debounceInputProductModal: decounce(function() {
      this.searchProductInModal();
    }, 500),
    fetchProduct(productName) {
      this.isSearching = true;
      this.listProduct = [];
      this.optionsProduct[0].data = [];

      ApiService.get(
        `productSearch/search-by-name?productName=${productName}`
      ).then(({ data }) => {
        const products = data.data;
        this.listProduct = products;

        products.map(element => {
          const item = {
            id: element.id,
            productName: element.productName,
            parentProductId: element.parentProductId,
            originalPrice: element.originalPrice,
            sellingPrice: element.sellingPrice
          };
          this.optionsProduct[0].data.push(item);
        });

        this.filteredOptionsProduct = [
          {
            data: this.optionsProduct[0].data
          }
        ];
        this.isSearching = false;
      });
    },
    fetchProductModal(productName) {
      this.isModalSearching = true;
      this.listProduct = [];
      this.optionsProduct[0].data = [];

      ApiService.get(
        `productSearch/search-by-name?productName=${productName}`
      ).then(({ data }) => {
        const products = data.data;
        this.listProduct = products;

        products.map(element => {
          const item = {
            id: element.id,
            productName: element.productName,
            parentProductId: element.parentProductId,
            originalPrice: element.originalPrice,
            sellingPrice: element.sellingPrice
          };
          this.optionsProduct[0].data.push(item);
        });

        this.filteredOptionsProductModal = [
          {
            data: this.optionsProduct[0].data
          }
        ];
        this.isModalSearching = false;
      });
    },
    searchProductInFilter() {
      if (!this.isSearching) {
        this.fetchProduct(this.searchProduct);
      }
    },
    searchProductInModal() {
      if (!this.isModalSearching) {
        this.fetchProductModal(this.searchProductModal);
      }
    },
    onSelectedProduct(option) {
      this.productIdFilter = option.item.id;
      this.searchProduct = option.item.productName;
    },
    onSelectedProductModal(option) {
      this.productIdModal = option.item.id;
      this.searchProductModal = option.item.productName;
    },
    createProductPrice() {
      if (this.sellingPrice === null || this.sellingPrice === 0) {
        makeToastFaile('Giá không được để trống hoặc bằng 0');
        return;
      }

      this.isBusy = true;
      const payload = {
        productId: this.productIdModal,
        storeIds: this.optionStoreModal
          .filter(item => item.checked)
          .map(item => item.code),
        sellingPrice: unMaskPrice(this.sellingPrice)
      };

      ApiService.post('product_price', payload)
        .then(() => {
          this.isBusy = false;
          makeToastSuccess('Lưu thành công');
          this.closeModal();
          this.resetFilter();
          this.fetchData();
        })
        .catch(async err => {
          let msg = get(err, 'response.data.message');
          makeToastFaile(`Lưu thất bại. Lý do ${msg}`);
          this.delay();
          this.closeModal();
        });
    },
    handlePageChange(page) {
      this.currentPage = page;
      this.fetchData();
    },
    deleteProductPrice(item) {
      ApiService.delete(`/product_price/${item.id}`)
        .then(async () => {
          makeToastSuccess('Xóa thành công');
          await this.delay();
          this.fetchData();
        })
        .catch(err => {
          makeToastFaile('Xóa thất bại: ', err);
        });
    },
    editProductPrice(item) {
      this.$router.push({
        name: 'update-product-price',
        params: { id: item.id }
      });
    },
    delay(time = 1000) {
      return new Promise(resolve => {
        setTimeout(() => {
          resolve();
        }, time);
      });
    },
    checkPermission,
    showDeleteAlert: function (item) {
      Swal.fire({
        title: 'Xóa cấu hình',
        text: 'Bạn có chắc muốn xóa cấu hình này không ?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: 'rgb(221, 51, 51)',
        cancelButtonColor: '#E5EAEE',
        confirmButtonText: 'Xóa',
        cancelButtonText: '<span style="color:#464E5F">Hủy</span>',
        reverseButtons: true,
        showClass: {
          popup: 'animate__animated animate__fadeInDown'
        },
        hideClass: {
          popup: 'animate__animated animate__fadeOutUp'
        },
        position: 'top'
      }).then(result => {
        if (result.value) {
          this.deleteProductPrice(item);
        }
      });
    },
  },
};
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style>
.add-button {
  font-size: 1rem;
}

.multiselect {
  min-height: initial;
}

.checkbox-label {
  display: block;
}

.multiselect__tags {
  display: block;
  padding: 3px 40px 0 8px;
  border-radius: 5px;
  border: 1px solid #e8e8e8;
  background: #fff;
  font-size: 12px;
  min-height: initial;
}

.multiselect__element {
  min-height: initial;
  display: block;
  background: #fff;
  font-size: 12px;
}
.multiselect__input {
  min-height: initial;
  display: block;
  background: #fff;
  font-size: 12px;
}
.multiselect__single {
  font-size: 12px;
}

.autosuggest__results ul {
  list-style: none;
  cursor: pointer;
  margin-bottom: 0;
}

.autosuggest__results {
  position: absolute;
  background-color: #fff;
  z-index: 1;
  width: 100%;
  border: 1px solid #e0e0e0;
  overflow: scroll;
  max-height: 200px;
  position: absolute;
}

.autosuggest__results-container {
  position: relative;
  width: 100%;
  z-index: 10000001;
  font-weight: 500;
  background: white;
}

.autosuggest__pp_product {
  display: block;
  width: 100%;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  height: calc(1.35em + 1.1rem + 2px);
}

.autosuggest__pp_product:focus {
  outline: none !important;
  border: 1px solid #92c5f9;
}

.autosuggest__results-item:hover {
  background-color: #ddd;
}

.autosuggest__results ul li {
  padding: 8px;
}

.test {
  position: absolute;
  right: 1vw;
}
</style>
